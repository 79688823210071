import React, {useState} from 'react';
import styled from 'styled-components';

interface Category {
    name: string;
    questions: { question: string; answer: string; answerVisible: boolean }[]; // Add answerVisible property
  }

interface SidebarProps {
    categories: Category[];
    onCategoryClick: (category: Category) => void;
  }
 
  
  
  const SideBar: React.FC<SidebarProps> = ({ categories, onCategoryClick }) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(categories[0]);
            
    const handleCategoryClick = (category: Category) => {
      setActiveCategory(category);
      onCategoryClick(category);
      
    };

    return (
        <SidebarContainer>

            <StyledButtons>
              {categories.map((category, index) => (
                <ButtonListItem
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  className={category === activeCategory ? 'active' : ''}
                  isactive={category === activeCategory ? true : false}
                >
                  {category.name}
                </ButtonListItem>
              ))}
            </StyledButtons>

        </SidebarContainer>
      );
    };

interface ButtonProps {
  isactive?: boolean;
}

const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  top: 0px;
  background-color: transparent;
  color: #fff;
  border: 0px solid #fff;
  justify-content: center;
  z-index: 0;
  margin-top: 10px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
const StyledTitle = styled.div`
  position: fixed;
  top: 170px;
  left: 45px;
  color: #fff;
  font-size: 24px;
  font-weight: 650;
  padding: 5px;
  border: 0px solid red;
  justify-content: center;
  @media (max-width: 800px) {
    width: 130px;
    font-size: 18px;
    left: 0px;
  }
`;
const StyledButtons = styled.div`
  position: relative;
  display: flex;
  border: 0px solid red;
  justify-content: center;
  gap: 10px;
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
const ButtonListItem = styled.div<ButtonProps>`
  cursor: pointer;
  position: relative;
  justify-content: center;
  width: 100%;
  background-color: #111;
  padding: 5px;
  border: 2px outset ${(props) => (props.isactive ? '#fff' : '#0AC18E')};
  border-radius: 0px;
  font-size: 18px;
  &:hover {
    background-color: ${(props) => (props.isactive ? '#111' : '#004295')};
  }
  &.active {
    background-color: #06a679; /* Solid color for the active button */
  }
`;




export default SideBar;
