import styled, { keyframes } from 'styled-components';

import React, { useState, useEffect } from 'react';

interface ErrorNotificationProps {
    message: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
  }

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ message, setError }) => {
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (message) {
      setHidden(false);
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        setTimeout(() => setError(''), 500);  //reset error state for next trigger
      }, 7000); // Show the div for 7 seconds

      return () => clearTimeout(timer);
    }
  }, [message, setError]);

  //if (!show) return null;

  return (
    <StyledErrorDiv show={show} isError={message.startsWith('Error') ? true : false} hidden={hidden}>
      {message}
    </StyledErrorDiv>
  );
};

interface ErrorParams {
    show: boolean;
    isError: boolean;
    hidden: boolean;
  }

const slideIn = keyframes`
  from {
    right: -600px; // Adjust width based on the actual width of the ErrorDiv
  }
  to {
    right: 10px;
  }
`;
const slideOut = keyframes`
  from {
    right: 10px;
  }
  to {
    right: -600px; // Adjust width based on the actual width of the ErrorDiv
  }
`;
const StyledErrorDiv = styled.div<ErrorParams>`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  top: 20px;
  right: -300px;
  background-color: ${props => props.isError ? 'red' : 'green'};
  color: white;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 4px;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  width: 400px;
  max-width: 400px;
  min-width: 300px;
  z-index: 1000;
  opacity: ${props => props.hidden ? 0 : 1};
  animation: ${props => props.show ? slideIn : slideOut} 0.5s forwards;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: visible;
`;

export default ErrorNotification;