import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CasualLogo from '../../assets/img/casualLogo.png';
import BCHLogo from '../../assets/img/bch.png';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import { Utxo, Contract } from 'cashscript';
import ImageSpinner from '../../assets/img/BCH2.gif';
//blockchain stuff
import { AddressCashStarter, MasterCategoryID } from '../../constants/values'
import Spinner from '../../assets/img/spinner.gif';
import axios, { AxiosError } from 'axios';
import ErrorNotification from '../../components/ErrorNotification';
import { environmentUrl } from '../../constants/environment';

interface CampaignUtxo extends Utxo {
  name: string;
  owner: string;
  shortDescription: string;
  banner: string;
  endDate: string;
}
interface ArchivedCampaign {
  id: number;
  name: string;
  owner: string;
  shortDescription: string;
  banner: string;
  endDate: string;
}

const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const { electrumServer } = useBlockchainContext();
  const [campaigns, setCampaigns] = useState<Utxo[]>([]);
  const [expiredCampaigns, setExpiredCampaigns] = useState<Utxo[]>([]);
  const [campaignsMap, setCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [expiredCampaignsMap, setExpiredCampaignsMap] = useState<Map<number, CampaignUtxo | null>>(new Map());
  const [archivedCampaignsMap, setArchivedCampaignsMap] = useState<Map<number, ArchivedCampaign | null>>(new Map());
  const [stringPledgeAmount, setStringPledgeAmount] = useState<string>("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<string>('active');
  const [txPending, setTxPending] = useState(false);
  const [error, setError] = useState('');
  //fundme stats
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [totalBCHRaised, setTotalBCHRaised] = useState<number>(0);
  const [totalPledges, setTotalPledges] = useState<number>(0);

  const hexToDecimal = (hex: string): number => {
    const bigEndianHex = hex.match(/.{2}/g)?.reverse().join('') ?? '0';
    return parseInt(bigEndianHex, 16);
  };

  //click Details to open campaign details page
  const handleDetailsClick = (id: number) => {
    navigate(`/campaign/${id}`);
  };

//////////////////////////////////////////////////
////////// UseEffect: Fetch stats on page load
//////////////////////////////////////////////////
  useEffect(() => {
    async function fetchStats() {
      try {
        // Fetch stats from server
        const statsResponse = await axios.get(environmentUrl + '/get-stats');
        const statsData = statsResponse.data;
        
        // Set initial stats
        setTotalCampaigns(statsData.totalCampaigns);
        setTotalBCHRaised(statsData.totalRaisedBCH);
        setTotalPledges(statsData.totalPledges);

      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Error loading stats');
      }
    }
    fetchStats();
  }, []);

//////////////////////////////////////////////////
////////// UseEffect: Fetch campaigns on page load
//////////////////////////////////////////////////
  useEffect(() => {
    setIsLoading(true);  //starts loading spinner graphic
    
    async function getCampaigns() {
      if (!electrumServer) return;
      console.log('electrumServer detected');

      //delay to allow electrum to stabilize
      setTimeout(async () => {
        //get full list of campaigns hosted on FundMe server
        const fetchedCampaigns = await axios.get(environmentUrl + '/get-campaignlist');
        let campaignList = fetchedCampaigns.data;

        const cashStarterUTXOs: Utxo[] = await electrumServer.getUtxos(AddressCashStarter);
        //console.log(cashStarterUTXOs);
        console.log('found ' + cashStarterUTXOs.length + ' utxos on the CashStarter contract');
        const filteredUTXOs = cashStarterUTXOs.filter( 
          utxo => utxo.token?.category == MasterCategoryID                  //only CashStarter NFT's
            && utxo.token?.nft?.capability == 'minting'                     //only minting ones
            && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
        );
        console.log('found ' + filteredUTXOs.length + ' active campaigns on the CashStarter contract');
        setCampaigns(filteredUTXOs);  //save active campaigns

        //if no utxos exist after filtering out the masterNFT
        if (filteredUTXOs.length == 0) {
          setIsLoading(false);
          setIsEmpty(true);
        }

        const expiredUTXOs = cashStarterUTXOs.filter(utxo => 
          utxo.token?.category === MasterCategoryID                       // only CashStarter NFT's
          && utxo.token?.nft?.capability === 'mutable'                    // expired ones
          && utxo.token.nft?.commitment.substring(70, 80) != 'ffffffffff' //not the masterNFT
        );
        console.log('found ' + expiredUTXOs.length + ' expired campaigns on the CashStarter contract')
        setExpiredCampaigns(expiredUTXOs);  //save expired campaigns

        //Fill active map with active campaigns
        for (const utxo of filteredUTXOs) {                       //Iterate over filteredUTXOs to populate the map

          const campaignId = hexToDecimal(utxo.token?.nft?.commitment.substring(70,80) ?? "0");

          // Check if campaignId exists in the campaigns array
          const index = campaignList.indexOf(campaignId.toString());
          if (index !== -1) {
            // If found, remove it from the array
            campaignList.splice(index, 1);
          }

          const endBlock = hexToDecimal(utxo.token?.nft?.commitment.substring(52, 60) ?? "0");
          const endDate = await formatTime(endBlock);

          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaignId);
            const campaignInfo = response.data;

            const newCampaign = {
              ...utxo,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: endDate
            };
          
            // Update the state with the new campaign
            setCampaignsMap((prevCampaignsMap) => {
              const updatedMap = new Map(prevCampaignsMap);
              updatedMap.set(campaignId, newCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaignId} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        //Fill expired map with expired campaigns
        for (const utxo of expiredUTXOs) {                       //Iterate over expiredUTXOs to populate the map
          const campaignId = hexToDecimal(utxo.token?.nft?.commitment.substring(70,80) ?? "0");

            // Check if campaignId exists in the campaigns array
            const index = campaignList.indexOf(campaignId.toString());
            if (index !== -1) {
              // If found, remove it from the array
              campaignList.splice(index, 1);
            }
          const endBlock = hexToDecimal(utxo.token?.nft?.commitment.substring(52, 60) ?? "0");

          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaignId);
            const campaignInfo = response.data;

            const expiredCampaign = {
              ...utxo,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: endBlock.toString()
            };
            
            // Update the state with the new campaign
            setExpiredCampaignsMap((prevExpiredCampaignsMap) => {
              const updatedMap = new Map(prevExpiredCampaignsMap);
              updatedMap.set(campaignId, expiredCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaignId} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        //Fill archived map with archived campaigns
        for (const campaign of campaignList) {                       //Iterate over campaignList to populate the map
          //fetch campaign metadata from server
          try {
            const response = await axios.get(environmentUrl + '/get-shortcampaign/' + campaign);
            const campaignInfo = response.data;

            const archivedCampaign = {
              id: campaign,
              name: campaignInfo.name,
              owner: campaignInfo.owner,
              shortDescription: campaignInfo.shortDescription,
              banner: campaignInfo.banner,
              endDate: 'archived'
            };
            
            // Update the state with the new campaign
            setArchivedCampaignsMap((prevArchivedCampaignsMap) => {
              const updatedMap = new Map(prevArchivedCampaignsMap);
              updatedMap.set(campaign, archivedCampaign);
              return updatedMap;
            });

          } catch (err) { //if server does not have the campaignId files hosted
            const error = err as AxiosError;
            if (error.response && error.response.status === 404) {
              console.log(`fetch error, campaign ${campaign} not hosted on FundMe`);
            } else {
              console.log('fetch unknown error: ', err);
            }
          }
        };

        setIsLoading(false);
    }, 2000); // Delay of 2 seconds);
  }
    getCampaigns();

  }, [electrumServer]);

  async function formatTime(blocks: number): Promise<string> {
    const blockHeight = await electrumServer.getBlockHeight();
    const blocksRemaining = Math.max(blocks - blockHeight, 0);

    if (blocksRemaining == 0) {
      return 'Expired';
    }

    const totalMinutes = blocksRemaining * 10;
    const totalHours = totalMinutes / 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingHours - hours) * 60);

    let endDate = '';
    if (days > 0) {
      endDate += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
      endDate += `${hours}h `;
    }
    endDate += `${minutes}m`;

    return endDate;
  }

  return (
    <Container>
      <StyledBackground />
      <ErrorNotification message={error} setError={setError} />
      
      <StatsRow>
        <StatBox>
          <StatLabel>Past Campaigns</StatLabel>
          <StatValue>{totalCampaigns}</StatValue>
        </StatBox>
        <StatBox>
          <StatLabel>Active Campaigns</StatLabel>
          <StatValue>{campaigns.length}</StatValue>
        </StatBox>
        <StatBox>
          <StatLabel>Total Raised</StatLabel>
          <StyledRaisedRow>
            <StyledBCH />
            <StatValue>{totalBCHRaised.toFixed(2)}</StatValue>
          </StyledRaisedRow>
        </StatBox>
        <StatBox>
          <StatLabel>Total Pledges</StatLabel>
          <StatValue>{totalPledges}</StatValue>
        </StatBox>
      </StatsRow>

      <StyledCampaignButtonRow>
        <CampaignTypeButton onClick={() => setCampaignType('active')} isactive={campaignType == 'active'}>Active</CampaignTypeButton> 
        <CampaignTypeButton onClick={() => setCampaignType('stopped')} isactive={campaignType == 'stopped'}>Stopped</CampaignTypeButton> 
        <CampaignTypeButton onClick={() => setCampaignType('archived')} isactive={campaignType == 'archived'}>Archived</CampaignTypeButton> 
      </StyledCampaignButtonRow>

      {isEmpty && campaignType == 'active' &&
        <StyledExpiredTitle>No campaigns currently active.</StyledExpiredTitle>
      }

      {campaignType == 'active' &&
        <StyledCards>
          {[...campaignsMap.values()].map((campaign, index) => (
            campaign && (
            <CampaignCard key={campaign.txid}>
              <StyledLogo logoUrl={campaign.banner}/>
              <DetailsButton disabled={campaign.shortDescription == 'Campaign pending listing approval'} onClick={() => handleDetailsClick(hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0"))}>Details</DetailsButton>
              <StyledAdBox>
                <ProgressBar>
                  <Progress progress={(Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                  <ProgressBarText>{((Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                  <ProgressBarSubText>{(Number(campaign.satoshis) / 100000000 )} / {(hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                </ProgressBar>

                <StyledRow>
                  <StyledName>{campaign.name}</StyledName>
                  <StyledEnd>Ends:<span style={{fontWeight: '500'}}>{campaign.endDate}</span></StyledEnd>
                </StyledRow>

                <StyledDescription>
                  {campaign.shortDescription}
                </StyledDescription>

              <StyledFooterRow>
                <StyledCampaignID>Campaign #{hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledCampaignID>
                <StyledOwner>By: {campaign.owner}</StyledOwner>
              </StyledFooterRow>

              </StyledAdBox>
            </CampaignCard>
          )))}
          {isLoading == true &&
            <StyledSpinner />
          }
        </StyledCards>
      }

      {campaignType == 'stopped' && expiredCampaignsMap.size > 0 &&
        <StyledExpiredTitle>Refunds can be done in Details.</StyledExpiredTitle>
      }
      {campaignType == 'stopped' && expiredCampaignsMap.size == 0 && !isLoading &&
        <StyledExpiredTitle>No campaigns currently stopped.</StyledExpiredTitle>
      }
      {campaignType == 'stopped' &&
        <StyledCards>
            {[...expiredCampaignsMap.values()].map((campaign, index) => (
              campaign && (
                <CampaignCard key={campaign.txid}>
                  <StyledLogo logoUrl={campaign.banner}/>
                  <DetailsButton onClick={() => handleDetailsClick(hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0"))}>Details</DetailsButton>
                  <StyledAdBox>
                    <ProgressBar>
                      <Progress progress={(Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100} />
                      <ProgressBarText>{((Number(campaign.satoshis) / hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0")) * 100).toFixed(2)}%</ProgressBarText>
                      <ProgressBarSubText>{(Number(campaign.satoshis) / 100000000 )} / {(hexToDecimal(campaign.token?.nft?.commitment.substring(0, 12) ?? "0") / 100000000)}</ProgressBarSubText>
                    </ProgressBar>

                    <StyledRow>
                      <StyledName>Campaign #{hexToDecimal(campaign.token?.nft?.commitment.substring(70,80) ?? "0")}</StyledName>
                      <StyledEnd>End Block:{campaign.endDate}</StyledEnd>
                    </StyledRow>

                    <StyledDescription>
                      {campaign.shortDescription}
                    </StyledDescription>

                    <StyledFooterRow>
                      <StyledCampaignID>{campaign.name}</StyledCampaignID>
                      <StyledOwner>By: {campaign.owner}</StyledOwner>
                    </StyledFooterRow>

                  </StyledAdBox>
                </CampaignCard>
              )))}
              {isLoading == true &&
                <StyledSpinner />
              }
        </StyledCards>
      }

      {campaignType == 'archived' &&

        <StyledCards>
          {[...archivedCampaignsMap.values()].map((campaign, index) => (
            campaign && (
              <CampaignCard key={campaign.id}>
                <StyledLogo logoUrl={campaign.banner}/>
                <DetailsButton onClick={() => handleDetailsClick(campaign.id)}>Details</DetailsButton>
                <StyledAdBox>

                  <StyledRow>
                    <StyledName>Campaign #{campaign.id}</StyledName>
                  </StyledRow>

                  <StyledDescription>
                    {campaign.shortDescription}
                  </StyledDescription>

                  <StyledFooterRow>
                    <StyledCampaignID>{campaign.name}</StyledCampaignID>
                    <StyledOwner>By: {campaign.owner}</StyledOwner>
                  </StyledFooterRow>

                </StyledAdBox>
              </CampaignCard>
            )))
          }

            {isLoading == true &&
              <StyledSpinner />
            }
        </StyledCards>
      }

    </Container>
  );
};

interface LogoDivProps {
  logoUrl: string;
}
interface PledgeButtonProps {
  isactive?: boolean;
}
interface CampaignTypeButtonProps {
  isactive?: boolean;
}
interface DetailsButtonProps {
  isactive?: boolean;
}

const sheenAnimation = keyframes`
0%, 25% { // Sheen visible and starts moving (0% to 25% of the animation duration)
  left: -30%;
}
50% { // Sheen finishes moving (at 50% of the animation duration)
  left: 100%;
}
50.1%, 100% { // Sheen is not visible or stationary for the rest of the duration
  left: -30%;
}
`;
const fillAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh; // Full viewport height
  width: 100%; // Full viewport width
  background: #000;
  border: 0px solid yellow;
  a {
    color: #0AC18E;
    text-decoration: none;
    &:hover {
      color: #09af81; /* Hover link color */
    }
  }
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
`;
const StyledBCH = styled.div`
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  display: flex;
  height: 35px;
  width: 35px;
  border: 0px solid red;
  @media (max-width: 600px) {
    height: 28px;
    width: 28px;
  }
`;
const StyledCards = styled.div`
  border: 0px solid red;
  color: white;
  height: 100%;
  width: 90%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 5px;
  margin-bottom: 10px;
  border-radius: 0px 0px 0px 0px;
`;
const DetailsButton = styled.button<DetailsButtonProps>`
  position: absolute;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #F8F8F8;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  top: 0px;
  right: 0px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
  }
  @media (max-width: 520px) {
    width: 100px;
  }
`
const CampaignCard = styled.div`
  background-color: #000;
  border: 1px solid #00cc66;
  margin: 5px;
  color: white;
  height: 315px;
  width: 500px;
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 15px 0px 15px 15px;
  &:hover {
    border: 1px solid #fff;
    ${DetailsButton} {
      display: block;
      opacity: 1;
      visibility: visible;
      pointer-events: auto; // Enable click events on hover
    }
  }
`;
const StyledExpiredTitle = styled.div`
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 40px;
  border: 0px solid red;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  top: 5px;
  left: 0px;
  text-decoration: none;
  overflow: hidden;
  color: #F8F8F8;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 520px) {
    font-size: 14px;
  }
`;
const StyledCampaignID = styled.div`
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: 2px;
  padding-left: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #F8F8F8;
`;
const StyledOwner = styled.div`
  position: absolute;
  right: 5px;
  top: 2px;
  height: 25px;
  border: 0px solid red;
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  text-decoration: none;
  text-align: right;
  overflow: hidden;
  color: #F8F8F8;
`;
const StyledAdBox = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  top: 125px;
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;
const StyledDescription = styled.div`
  position: relative;
  padding: 5px;
  width: 98%;
  height: 120px;
  top: 10px;
  border: 0px solid yellow;
  font-size: 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @media (max-width: 550px) {
    font-size: 14px;
  }
`;
const StyledFooterRow = styled.div`
  position: relative;
  height: 25px;
  top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #0AC18E;
  border-left: 0px solid red;
  border-right: 0px solid red;
  border-bottom: 0px solid red;
  z-index: 2;
`;
const StyledRow = styled.div`
  position: relative;
  top: 0px;
  height: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  padding-left: 5px;
  border: 0px solid blue;
`;
const StyledRaisedRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
const StyledCampaignButtonRow = styled.div`
  position: relative;
  top: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
  padding-left: 5px;
  border: 0px solid red;
  align-items: center;
  justify-content: center;
`;
const CampaignTypeButton = styled.button<CampaignTypeButtonProps>`
  position: relative;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  display: flex;
  background-color: ${(props) => (props.isactive ? '#0AC18E' : '#000')};
  color: #F8F8F8;
  border: 1px solid ${(props) => (props.isactive ? '#fff' : '#0AC18E')};;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
`
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 40%;
  border: 0px solid orange;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
const StyledName = styled.div`
  position: relative;
  width: 340px;
  height: 40px;
  line-height: 18px;
  display: flex;
  border: 0px solid white;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: #F8F8F8;
  @media (max-width: 570px) {
    font-size: 14px;
    width: 255px;
  }
`;
const StyledEnd = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  border: 0px solid pink;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: #F8F8F8;
  @media (max-width: 550px) {
    font-size: 14px;
  }
`;
const ProgressBar = styled.div`
  position: relative;
  height: 45px;
  width: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  background-color: #84b6a8;
  border: 0px solid white;
  overflow: hidden;
  z-index: 2;
  flex-direction: row;
`;
const ProgressBarText = styled.div`
  position: absolute;
  top: -2px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  overflow: hidden;
  z-index: 3;
`;
const ProgressBarSubText = styled.div`
  position: absolute;
  top: 15px;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  overflow: hidden;
  z-index: 3;
`;
const Progress = styled.div<{ progress: number }>`
  display: flex;
  position: absolute;
  left: 0px;
  background-image: linear-gradient(to top, #024d38, #51e0b8); // Gradient for glass effect
  width: ${props => props.progress}%;
  height: 45px;
  overflow: hidden;
  z-index: 2;
  justify-content: left;
  align-items: left;
  animation: ${fillAnimation} 2s ease-out; // Apply the filling animation

  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3), // Inner shadow for depth
              inset 0 2px 2px rgba(0,0,0,0.2); // More depth

  transform-origin: left;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    animation: ${sheenAnimation} 3.5s ease-in-out infinite;
  }
`;
const StyledSpinner = styled.div`
  position: relative;
  top: 50px;
  box-shadow: 6px 6px 5px rgba(0,0,0,0.6);
  background-image: url(${ImageSpinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  display: flex;
  border: 1px solid #0AC18E;;
  z-index: 100;
`;
//Stats Row
const StatsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #0AC18E;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    gap: 10px;
    padding: 15px 0;
  }
`;
const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(10, 193, 142, 0.1);
  border: 1px solid #0AC18E;
  @media (max-width: 600px) {
    min-width: 150px;
    padding: 5px;
  }
`;
const StatValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #0AC18E;
  margin-bottom: 5px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const StatLabel = styled.div`
  font-size: 16px;
  color: #F8F8F8;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export default Campaigns;
