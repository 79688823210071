import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//contract artifacts
import ArtifactCashStarterManager from '../../contracts/FundMeV8_Mainnet/json/CashStarterManager.json';
//blockchain
import { ElectrumNetworkProvider, Contract, TransactionBuilder, Network } from 'cashscript';
import { decodeCashAddress, encodeCashAddress, stringify } from '@bitauth/libauth';
//functions
import consolidateUTXOs from '../../functions/consolidateUTXOs';
import managerInitialize from '../../functions/managerInitialize';
import { useBlockchainContext } from '../../components/Context/BlockchainContext';
import CasualLogo from '../../assets/img/casualLogo.png';
import ImageRefresh from '../../assets/img/refresh.png';
import ImageRefreshHighlight from '../../assets/img/refreshHighlight.png';
import ImageWizard from '../../assets/img/wizard.png';
import BCHLogo from '../../assets/img/bch.png';
import axios from 'axios';
import RichTextEditor from '../../components/RichTextEditor';
import ErrorNotification from '../../components/ErrorNotification';
import Spinner from '../../assets/img/spinner.gif';
//Calendar selector
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { createGlobalStyle } from 'styled-components';
import { environmentUrl } from '../../constants/environment';
import { Buffer } from 'buffer';

//styling for date and time popup calendar
const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    background-color: #222;
    border: 0px solid #0AC18E;
    z-index:10001;
  }
  .react-datepicker__day {
    color: #fff;
}
  .react-datepicker__day--selected {
    background-color: #0AC18E;
  }
  .react-datepicker__day:hover {
    color: #000;
  }
  .react-datepicker__header {
    background-color: #099971;
  }
  .react-datepicker__current-month {
    color: #fff;
  }
  .react-datepicker__day-name {
    color: #fff;
  }

  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__time-box {
    background: #222;
  }
  .react-datepicker__time-list-item {
    color: #fff; 
  }
  .react-datepicker__time-list-item:hover {
    color: #000; 
  }
  .react-datepicker__time-list-item--selected {
    background-color: #099971 !important; /* Change to your preferred color for selected time */
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
`;


const Create: React.FC = () => {
  //user
  const { walletConnectSession, walletConnectInstance, electrumServer, electrumCluster, usersAddress, connectedChain } = useBlockchainContext();
  //contracts
  const [transactionBuilder, setTransactionBuilder] = useState<TransactionBuilder>();
  const [contractsOK, setContractsOK] = useState(false);
  const [contractManager, setContractManager] = useState<Contract>();
  //user input values
  const [fundTarget, setFundTarget] = useState('');
  const [endingBlock, setEndingBlock] = useState(0);
  //blockchain stuff
  const [blockHeight, setBlockHeight] = useState<number>(0);
  //user input for campaign
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [txPending, setTxPending] = useState(false);
  const [error, setError] = useState('');
  //const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);
    return futureDate;
  });
  const MAX_CAMPAIGN_SIZE_MB = 25; // Maximum allowed size in MB

////////////////////////////////////////////////// 
////////// Initialize Transaction Builder
//////////////////////////////////////////////////
  if (!transactionBuilder) {
    const provider = new ElectrumNetworkProvider(Network.MAINNET);
    //const provider = new ElectrumNetworkProvider(Network.CHIPNET);
    setTransactionBuilder(new TransactionBuilder({ provider }));
  }

//////////////////////////////////////////////////
////////// Initial UseEffect: Initialize Contracts on page load
//////////////////////////////////////////////////
  useEffect(() => {
    console.log('1. useEffect called');
    async function checkAndCompileContracts() {
      //delay to allow electrum to stabilize
      setTimeout(async () => {
        if (!electrumServer) {
          console.log('Electrum server is not yet available.');
          return;
        }
        console.log('2. compiling contracts...');
        if (contractsOK === false) {
          try {
            const compiledCashStarterManager = await compileContract(ArtifactCashStarterManager, []);
            setContractManager(compiledCashStarterManager);
            setContractsOK(true);
            console.log('3. contracts OK');
          } catch (error) {
            console.log('contract compiling error: ' + error);
          }
        }
        const blockHeight = await electrumServer.getBlockHeight();
        setBlockHeight(blockHeight);

        handleEndBlockUpdate(endDate, blockHeight);
      }, 2000);
    }
  
    checkAndCompileContracts();
  }, [electrumServer]);

//////////////////////////////////////////////////
////////// Sign Transaction with WalletConnect
//////////////////////////////////////////////////
async function signTransaction(options: any) {
  console.log('signing transaction...');
  try {
    if (walletConnectInstance) {
      const params = JSON.parse(stringify(options));
      console.log('wc params:');
      console.log(params);
      const result = await walletConnectInstance.request({
        chainId: connectedChain,
        topic: walletConnectSession.topic,
        request: {
          method: "bch_signTransaction",
          params: params,
        },
      });
      return result;
    }
  } catch (error) {
    console.log('signTransation error: ' + error);
    setError('Error signing transaction');
    return undefined;
  }
}

//////////////////////////////////////////////////
//////////Convert address to token address
//////////////////////////////////////////////////
/*
function toTokenAddress(address: string) {
  console.log('toTokenAddress() called for address: ' + address);
  const addressInfo: any  = decodeCashAddress(address);
  const pkhPayoutBin = addressInfo.payload;
  const prefix = 'bitcoincash';
  const tokenAddress = encodeCashAddress(prefix, "p2pkhWithTokens", pkhPayoutBin);
  console.log('toTokenAddress() converted it to: ' + tokenAddress);
  return tokenAddress;
}
*/
//////////////////////////////////////////////////
//////////Compile an artifact into a CashScript Contract object
//////////////////////////////////////////////////
async function compileContract(contractArtifact: any, args: any[]) {
  const contract = new Contract(contractArtifact, args, {provider: electrumServer, addressType: 'p2sh32'});
  return contract;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// Contract Functions  /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

async function handleConsolidateUtxos() {
  setTxPending(true);

  if (electrumServer && usersAddress && transactionBuilder) { 
    const signResult = await consolidateUTXOs({electrumServer, usersAddress, transactionBuilder, signTransaction, setError });
    const rawTx = signResult.signedTransaction; 
    console.log('signedTransaction from walletconnect: ');
    console.log(signResult);
    
    electrumServer.sendRawTransaction(rawTx).then((result: string) => {
      console.log('Broadcasted, txid: ' + result);
    }).catch((error: Error) => {
      setError('Error consolidating: ' + error);
      console.log('Error consolidating: ', error);
    });

  } else {
    console.log('Error consolidating, connect wallet or refresh?');
    setError('Error consolidating, connect wallet or refresh?');
  }
  
  setTxPending(false);
}

const handleCreateCampaign = async () => {
  setTxPending(true);

  if (electrumServer && usersAddress && name != '' && owner != '' && description != '' && shortDescription != '' && logo != '' && banner != '') {
    // Check the size of the campaign data
    const campaignData = JSON.stringify({
      name,
      owner,
      description,
      shortDescription,
      logo,
      banner
    });
    const campaignSizeMB = Buffer.byteLength(campaignData, 'utf8') / (1024 * 1024);

    if (campaignSizeMB > MAX_CAMPAIGN_SIZE_MB) {
      setError(`Error: Campaign size (${campaignSizeMB.toFixed(2)} MB) exceeds max of (${MAX_CAMPAIGN_SIZE_MB} MB)`);
      setTxPending(false);
      console.log('campaignSize error, size(MB): ', campaignSizeMB);
      return;
    }

    // Continue with campaign creation
    const pubkeyhash = getAddressesPKH(usersAddress)!;
    const endBlock = endingBlock.toString();

    const signResult = await managerInitialize({ electrumServer, usersAddress, contractManager, signTransaction, pubkeyhash, fundTarget, endBlock, setError })
  
    if (signResult!.signResult) {
      const rawTx = signResult!.signResult.signedTransaction;
      const campaignID = signResult!.newCampaignID.toString();

      console.log('signedTransaction from walletconnect: ');
      console.log(rawTx);

      try {
        const result = await electrumServer.sendRawTransaction(rawTx);
        console.log('Broadcasted, txid: ' + result);

        // Save to external server
        await axios.post(environmentUrl + '/save-campaign', { campaignID, name, owner, shortDescription, description, logo, banner, txid: result, usersAddress });
        setError(`New Campaign created! TxID:\n${result}`);
      } catch (error) {
        console.log('Error creating campaign: ', error);
        setError(`Error creating campaign:\n${error}`);
      }

    } else {
      console.log('Error creating campaign: ', error);
      setError(`Error creating campaign. ` + error);
    }

  } else {
    console.log('Error creating campaign. Did you provide all the campaign details?');
    console.log();
    setError(`Error creating campaign: ` + error);
  }
  
  setTxPending(false);
}

const handleSetFundTarget = (text: string) => {
  let newValue = text.replace(/[^0-9.]+/g, "").replace(/(\..*)\./g, '$1'); // Allow numbers and a single decimal point

  // Check if there's a decimal point, and if so, limit the length after it to 8
  const decimalIndex = newValue.indexOf('.');
  if (decimalIndex !== -1) {
    const integerPart = newValue.substring(0, decimalIndex);
    const decimalPart = newValue.substring(decimalIndex + 1, decimalIndex + 9); // Grab up to 8 decimal places
    newValue = `${integerPart}.${decimalPart}`;
  }

   // Parse the newValue as a floating-point number and check against the limit
   const numericValue = parseFloat(newValue);
   if (numericValue > 1407374) { // If the value exceeds 1407374, limit it to 1407374. Effectively restricts fundTarget to 5bytes, leaving the 6th byte for fundTarget to be empty (00) to satisfy minimally-encoded VM numbers
     newValue = '1407374';
   }

  setFundTarget(newValue);
};

//////////////////////////////////////////////////
/////Wallet Functions
//////////////////////////////////////////////////
  //convert address to pubkeyhash
  function getAddressesPKH(address: string) {
    try {
      const decoded = decodeCashAddress(address);
      if (typeof decoded === 'string') {
        console.log('Invalid address format');  // Handle error - decoded should be an object for a valid address
        setError('Error: Invalid address format');
        return null;
      }

      // Convert Uint8Array to a hexadecimal string
      const pubkeyhashHex = Array.from(decoded.payload)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return pubkeyhashHex;

    } catch (error) {
      console.error('Error decoding address:', error);
      setError('Error decoding address');
      return null;
    }
  }

//////////////////////////////////////////////////
///// Page Functions
//////////////////////////////////////////////////
const handleDateChange = (date: Date | null) => {
  setEndDate(date);
  handleEndBlockUpdate(date);
};

const handleEndBlockUpdate = (date: Date | null, initialHeight?: number) => {
  if (date) {
    const currentDate = new Date();   // Get the current date
    const differenceMs = date.getTime() - currentDate.getTime();  // Calculate the difference in milliseconds
    const differenceMinutes = Math.floor(differenceMs / (1000 * 60)); // Convert milliseconds to minutes
    const estimatedBlocks = Math.ceil(differenceMinutes / 10);  // Calculate the estimated number of blocks
    initialHeight ? 
      setEndingBlock(initialHeight + estimatedBlocks) : 
      setEndingBlock(blockHeight + estimatedBlocks);

  }
}

const handleImageSelect = () => {
  if (logo == ''){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files![0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 150 && img.height <= 150) {
            setLogo(e.target?.result as string);
            //setError('');
          } else {
            console.log('Logo image must be 150x150 pixels or smaller.')
            setError('Error: Logo image must be 150x150 pixels or smaller.');
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    };
  }
};
const handleBannerSelect = () => {
  if (banner == ''){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files![0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 500 && img.height <= 120) {
            setBanner(e.target?.result as string);
            //setError('');
          } else {
            console.log('Banner image must be 500x120 pixels or smaller.')
            setError('Error: Banner image must be 500x120 pixels or smaller.');
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    };
  }
};
const clearImage = () => {
  setLogo('');
};
const clearBanner = () => {
  setBanner('');
};
const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 300) {
    setShortDescription(e.target.value);
  }
};
const handleSetName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 50) {
    setName(e.target.value);
  }
};
const handleSetOwner = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length <= 20) {
    setOwner(e.target.value);
  }
};

  return (
  <StyledCreate>
    <StyledBackground />
    <DatePickerStyles />

    <ErrorNotification message={error} setError={setError} />

    <StyledTextSteps>
      <StyledTextBackground />
      <StyledWizard />

      <StyledNote>
        <StyledNoteTitle>NOTE</StyledNoteTitle>
        <StyledNoteText>
          <StyledTextCenter>
            <b>Launching a campaign costs <StyledBCHLogo size={20} />0.01<br/>
            Claiming a successful campaign costs 1.5% of the raised funds.</b>
          </StyledTextCenter>
        </StyledNoteText>
      </StyledNote>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 1:</span> Connect your wallet</StyledText>
      <StyledSubText>
        Connect your wallet to this website with the 'Connect' button above. The wallet must support <a href="https://walletconnect.com/">walletconnect v2</a>,
        such as Paytaca, Zapit, and Cashonize. 
        <p/>
        <StyledLogos>
          <a href="https://www.paytaca.com/"><StyledLogo logoUrl={require('../../assets/img/paytaca.png')}/></a>
          <a href="https://zapit.io/"><StyledLogo logoUrl={require('../../assets/img/zapit.png')}/></a>
          <a href="https://cashonize.com/"><StyledLogo logoUrl={require('../../assets/img/cashonize.png')}/></a>
        </StyledLogos>
      </StyledSubText>

        <StyledNote>
          <StyledNoteTitle>NOTE</StyledNoteTitle>
          <StyledNoteText>
            The address you are currently connected with is <b><span style={{ color: '#0ac18e' }}>the only address</span></b> that can Claim your campaign.<br/>
            The address you are currently connected with is <b><span style={{ color: '#0ac18e' }}>where the BCH will be sent</span></b> when you Claim your succesful campaign.<br/>
            FundMe.Cash <b><span style={{ color: '#c60000' }}>never</span></b> has control over your funds, and <b><span style={{ color: '#c60000' }}>cannot</span></b> help you recover them.<br/>
            It is <b><span style={{ color: '#c60000' }}>your responsibility</span></b> to backup your wallet.<br/>
            <StyledWarning>Backup your wallet!</StyledWarning>
          </StyledNoteText>
        </StyledNote>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 2:</span> Enter your Campaign details</StyledText>
        <StyledInputs>
          Campaign Ends:
          <DatePicker
            selected={endDate}
            onChange={handleDateChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledInputs>
        <StyledInputs>
          on block number: {blockHeight != 0 ? endingBlock : ''}
        </StyledInputs>
        
        <StyledInputs>
          Fundraising Amount <StyledBCHLogo size={26} />
          <input
            type="text"
            placeholder="123.45678900"
            value={fundTarget}
            onChange={(e) => handleSetFundTarget(e.target.value)}
          />
        </StyledInputs>

        <StyledNote>
            <StyledNoteTitle>NOTE</StyledNoteTitle>
            <StyledNoteText>Pledges can make your campaign go over 100% of the fundraising amount, so you can implement stretch goals yourself by setting targets in your campaign description and not claiming the camapign until those targets are reached.</StyledNoteText>
          </StyledNote>

        <Container1>
          <InputTitle
            placeholder="Campaign Title"
            value={name}
            onChange={handleSetName}
          />
          <InputOwner
            placeholder="Campaign Owner"
            value={owner}
            onChange={handleSetOwner}
          />
          {error && <ErrorText1>{error}</ErrorText1>}

          <StyledRow>
            <BannerPreview1 banner={banner} onClick={handleBannerSelect}>
              {!banner && <p>Click to select campaign banner (500x120)</p>}
              {banner && <ClearButton onClick={clearBanner}>×</ClearButton>}
            </BannerPreview1>
            <LogoPreview logo={logo} onClick={handleImageSelect}>
              {!logo && <p>Click to select your logo (150x150)</p>}
              {logo && <ClearButton onClick={clearImage}>×</ClearButton>}
            </LogoPreview>
          </StyledRow>
          <InputShortDescription
            placeholder="Campaign Short Description (max 300 characters)"
            value={shortDescription}
            onChange={handleDescriptionChange}
          />

          <StyledNote>
            <StyledNoteTitle>NOTE</StyledNoteTitle>
            <StyledNoteText>Save a copy of your campaign descriptions in case any upload issues occur.</StyledNoteText>
          </StyledNote>

          <RichTextEditor isUpdate={false} value={description} onChange={setDescription} />
        </Container1>

      <StyledText><span style={{ color: '#0ac18e' }}>Step 3:</span> Click 'Create Campaign'</StyledText>
      <StyledSubText>
        This asks your wallet for approval to create the campaign on the CashStarter smart contract.
        It will cost a total of <StyledBCHLogo size={20} />0.01002 to create the campaign. The cost breaks down as:<br/>
        <StyledUL>
          <li>Creation Fee: <StyledBCHLogo size={20} />0.01 paid to FundMe.Cash</li>
          <li>Contract Fee: <StyledBCHLogo size={20} />0.00001 used to create the campaign UTXO</li>
          <li>Miner Fee: <StyledBCHLogo size={20} />0.00001</li>
        </StyledUL>
      </StyledSubText>
      <StyledButton onClick={handleCreateCampaign}>
        {txPending ? (
          <StyledButtonSpinner />
        ) : (
          'Create Campaign'
        )}
      </StyledButton>

      <StyledNote>
        <StyledNoteTitle>NOTE</StyledNoteTitle>
        <StyledNoteText><i>No compatible UTXO</i> error? Try consolidating your wallets BCH into one UTXO:</StyledNoteText>
        <StyledNoteButton onClick={handleConsolidateUtxos}>
          {txPending ? (
            <StyledButtonSpinner />
          ) : (
            'Consolidate'
          )}
        </StyledNoteButton>
      </StyledNote>

      <StyledText>Done!</StyledText>

      <StyledSubText>
        Your campaign has been submitted to the CashStarter contract! However, it won't appear on FundMe.Cash until it is reviewed and approved.
        <ul>
          <li>People can pledge and refund at any time while your campaign exists on the smart contract.</li>
          <li>You can claim the pledges, ending the campaign, at any time if the campaign reaches or exceeds the BCH goal you set.</li>
          <li>If the deadline is reached anyone can 'Stop' the campaign which will prevent any new pledges.</li>
          <li>You <b>can</b> still claim the funds even if the campaign has been 'Stopped', but only if the campaign still meets or exceeds the initial goal.</li>
        </ul>
      </StyledSubText>
    </StyledTextSteps>

  </StyledCreate>
  );
};


interface StyledButtonProps {
  isactive?: boolean;
}
interface LogoDivProps {
  logoUrl: string;
}
interface StyledLogoProps {
  size: number;
}

const StyledCreate = styled.div`
  color: #fff; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #000;
  border: 0px solid red;
  a {
    color: #0AC18E;
    &:hover {
      color: #09af81;
    }
`;
const StyledBackground = styled.div`
  background-image: url(${CasualLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 816px;
  width: 1456px;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.4;
  @media (max-width: 670px) {
    width: 1020px;
    height: 571px;
  }
`;
const StyledWizard = styled.div`
  background-image: url(${ImageWizard});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -37px;
  right: 0px;
  display: flex;
  height: 500px;
  width: 332px;
  border: 0px solid red;
  z-index: 0;
  opacity: 0.2;
`;
const StyledTextSteps = styled.div`
background-color: transparent;
border: 1px solid #0AC18E;
border-top: 0px;
border-bottom: 0px;
display: flex;
flex-direction: column;
margin: 1px auto;
padding: 20px;
color: white;
height: 100%;
width: 50%;
position: relative;
text-align: center;
justify-content: center;
align-items: center;
  font-weight: 500;
@media (max-width: 1500px) {
  width: 80%;
}
`;
const StyledTextBackground = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-color: #000;
opacity: 0.75;
z-index: 0;
`;
const StyledText = styled.h1`
text-decoration: none;
font-size: 22px;
color: #fff;
border-bottom: 1px solid #0AC18E;
z-index: 1;
`;
const StyledTextCenter = styled.div`
text-align: center;
`;
const StyledWarning = styled.div`
color: #c60000;
font-size: 20px;
font-weight: 700;
text-align: center;
`;
const StyledSubText = styled.div`
position: relative;
font-size: 16px;
text-decoration: none;
color: #fff;
top: -10px;
border: 0px solid pink;
justify-content: center;
align-items: center;
text-align: left;
padding-left: 10px;
font-weight: 500;
`;
const StyledNoteTitle = styled.div`
position: absolute;
width: 100%;
height: 15px;
background: #0AC18E;
font-size: 11px;
text-decoration: none;
color: #fff;
top: 0px;
text-align: left;
font-weight: 700;
padding-left: 20px;
`;
const StyledNoteText = styled.div`
position: relative;
font-size: 14px;
text-decoration: none;
color: #fff;
top: 0px;
border: 0px solid pink;
justify-content: center;
align-items: center;
text-align: left;
font-weight: 400;
margin-top: 10px;
`;
const StyledNote = styled.div`
position: relative;
display: flex;
flex-direction: column;
color: #fff;
background: #002017;
top: 10px;
border: 2px outset #0AC18E;
border-radius: 10px;
justify-content: center;
align-items: center;
text-align: center;
padding: 10px;
gap: 5px;
overflow: hidden;
`;
const StyledInputs = styled.div`
background-color: black;
border: 0px solid #00cc66;
display: flex;
flex-direction: row;
margin: 1px auto;
padding: 1px;
color: white;
height: 100%;
width: 50%;
position: relative;
text-align: center;
justify-content: center;
align-items: center;
`;
const StyledRow = styled.div`
background-color: black;
border: 0px solid #00cc66;
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin: 1px auto;
padding: 1px;
color: white;
height: 180px;
width: 95%;
position: relative;
text-align: center;
justify-content: center;
align-items: center;
@media (max-width: 600px) {
  margin-bottom: 50px;
}
`;
const StyledLogos = styled.div`
display: flex;
flex-direction: row;
width: 100%;
position: relative;
text-align: center;
justify-content: center;
align-items: center;
gap: 10px;
`;
const StyledLogo = styled.div<LogoDivProps>`
  background-image: url(${props => props.logoUrl});
  background-size: cover;
  position: relative;
  width: 70px;
  height: 70px;
  border: 0px solid orange;
  border-radius: 25px 25px 25px 25px;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;
const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  right: 0px;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
`
const StyledNoteButton = styled.button<StyledButtonProps>`
  position: relative;
  display: flex;
  background-color: ${(props) => (props.isactive ? '#09af81' : '#0AC18E')};
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
  width: 100px;
  min-width: 50px;
  height: 24px;
  justify-content: center;
  text-align: center;
  align-items: center;
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
`
const Container1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 0px solid red;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;
const InputTitle = styled.textarea`
  width: 300px;
  padding: 5px;
  margin: 3px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  overflow: hidden;
  height: 25px;
  z-index: 1;
`;
const InputOwner = styled.textarea`
  width: 200px;
  padding: 5px;
  margin: 3px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: none;
  overflow: hidden;
  height: 15px;
  z-index: 1;
`;
const InputShortDescription = styled.textarea`
  display: flex;
  position: relative;
  width: 90%;
  min-width: 90%;
  max-width: 90%;
  height: 100px;
  min-height: 75px;
  padding: 5px;
  margin: 3px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  overflow-y: auto; /* Adds vertical scrollbar */
  resize: both; /* Allows resizing both width and height */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  `;
const LogoPreview = styled.div<{ logo: string }>`
  width: 150px;
  height: 150px;
  margin: 10px 0;
  background-color: #f0f0f0;
  background-image: url(${props => props.logo});
  background-size: cover;
  background-position: center;
  border: 3px solid #0ac18e;
  border-radius: 4px;
  overflow: visible;
  color: #333;
  @media (max-width: 1000px) {
    height: 100px;
    width: 100px;
  }
`;
const BannerPreview1 = styled.div<{ banner: string }>`
  width: 500px;
  height: 120px;
  margin: 10px 0;
  margin-right: 20px;
  background-color: #f0f0f0;
  background-image: url(${props => props.banner});
  background-size: cover;
  background-position: center;
  border: 3px solid #0ac18e;
  border-radius: 4px;
  overflow: visible;
  color: #333;
  @media (max-width: 1000px) {
    height: 72px;
    width: 300px;
  }
`;
const ErrorText1 = styled.div`
  color: red;
  margin-top: 10px;
  z-index: 1;
`;
const ClearButton = styled.button`
  position: relative;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 0;
  font-weight: 700;
`;
const StyledButtonSpinner = styled.div`
  position: relative;
  background-image: url(${Spinner});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  display: flex;
  border: 0px solid red;
`;
const StyledUL = styled.ul`
  line-height: 24px;
`;
const StyledBCHLogo = styled.div<StyledLogoProps>`
  background-image: url(${BCHLogo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: inline-block;
  vertical-align: bottom;
  border: 0px solid red;
  margin: 0 2px; // Add some horizontal spacing
`;
export default Create;
