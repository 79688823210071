import React, { createContext, useState, useContext, ReactNode } from 'react';
import SignClient from '@walletconnect/sign-client'
import { ElectrumCluster } from 'cashscript/node_modules/electrum-cash';
import { ElectrumNetworkProvider, Contract } from 'cashscript';

interface BlockchainContextType {
  walletConnectSession: any; // Replace 'any' with the appropriate type
  walletConnectInstance: any;
  electrumServer: any;
  electrumCluster: any;
  usersAddress: string;
  connectedChain: string;
  contractCashStarter: Contract | undefined;
  contractManager: Contract | undefined;
  contractCashStarterRefund: Contract | undefined;

  setBlockchainData: (data: { 
    session?: any, 
    instance?: any, 
    electrumServer?: any, 
    electrumCluster?: any, 
    usersAddress?: string, 
    connectedChain?: string,
    contractCashStarter?: Contract;
    contractManager?: Contract;
    contractCashStarterRefund?: Contract;
  }) => void;
}

const BlockchainContext = createContext<BlockchainContextType | undefined>(undefined);

export const useBlockchainContext = () => {
  const context = useContext(BlockchainContext);
  if (!context) throw new Error('useBlockchainContext must be used within a BlockchainProvider');
  return context;
};

interface BlockchainProviderProps {
    children: ReactNode;
}

export const BlockchainProvider: React.FC<BlockchainProviderProps> = ({ children }) => {
  const [walletConnectSession, setWalletConnectSession] = useState<any>(null);
  const [walletConnectInstance, setWalletConnectInstance] = useState<SignClient>();
  const [electrumServer, setElectrumServer] = useState<ElectrumNetworkProvider>();
  const [electrumCluster, setElectrumCluster] = useState<ElectrumCluster>();
  const [usersAddress, setUsersAddress] = useState<string>('');
  const [connectedChain, setConnectedChain] = useState<string>('');
  const [contractCashStarter, setContractCashStarter] = useState<Contract>();
  const [contractManager, setContractManager] = useState<Contract>();
  const [contractCashStarterRefund, setContractCashStarterRefund] = useState<Contract>();

  const setBlockchainData = (data: { 
    session?: any, 
    instance?: any, 
    electrumServer?: any, 
    electrumCluster?: any, 
    usersAddress?: string, 
    connectedChain?: string, 
    contractCashStarter?: Contract, 
    contractManager?: Contract,
    contractCashStarterRefund?: Contract
  }) => {
    if (data.session !== undefined) {
      setWalletConnectSession(data.session);
    }
    if (data.instance !== undefined) {
      setWalletConnectInstance(data.instance);
    }
    if (data.electrumServer !== undefined) {
      setElectrumServer(data.electrumServer);
    }
    if (data.electrumCluster !== undefined) {
      setElectrumCluster(data.electrumCluster);
    }
    if (data.usersAddress !== undefined) {
      setUsersAddress(data.usersAddress);
    }
    if (data.connectedChain !== undefined) {
      setConnectedChain(data.connectedChain);
    }
    if (data.contractCashStarter !== undefined) {
      setContractCashStarter(data.contractCashStarter);
    }
    if (data.contractManager !== undefined) {
      setContractManager(data.contractManager);
    }
    if (data.contractCashStarterRefund !== undefined) {
      setContractCashStarterRefund(data.contractCashStarterRefund);
    }
  };
  
  return (
    <BlockchainContext.Provider value={{ 
      walletConnectSession, 
      walletConnectInstance, 
      electrumServer, 
      electrumCluster, 
      usersAddress, 
      connectedChain, 
      setBlockchainData,
      contractCashStarter,
      contractManager,
      contractCashStarterRefund
    }}>
      {children}
    </BlockchainContext.Provider>
  );
};