import React, { useState } from 'react';
import styled from 'styled-components';
import BCHLogo from '../../assets/img/bch.png';

interface ConsolidateModalProps {
  onRequestClose: () => void;
  onSubmit: () => void;
  stringPledgeAmount: string;
}

const ConsolidateModal: React.FC<ConsolidateModalProps> = ({ onRequestClose, onSubmit, stringPledgeAmount }) => {

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
    onRequestClose();
  };

 return (
    <ModalOverlay onClick={onRequestClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalCloseButton onClick={onRequestClose}>&times;</ModalCloseButton>
        <StyledTitle>Combine your UTXOs</StyledTitle>
          <p />
          You tried to pledge {stringPledgeAmount} BCH but your wallet has the coins spread across multiple UTXOs. FundMe needs them to be on one UTXO.
          <p />
           The Combine button will merge them, then you can try pledging again.
        <StyledForm onSubmit={handleSubmit}>
          <SubmitButton type="submit">Combine</SubmitButton>
        </StyledForm>
        <p />
        If this still doesn't work then in your wallet try sending all your BCH to your wallets own receiving address and try again.
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #000;
  color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  height: 340px;
  border: 2px solid #0AC18E;
  justify-content: center;
  align-items: center;
`;
const ModalCloseButton = styled.button`
  position: absolute;
  display: flex;
  color: #f8f8f8;
  top: 2px;
  right: 5px;
  background: #000;
  height: 30px;
  border: 2px solid #f8f8f8;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 19px;
  cursor: pointer;
  z-index: 5;
  align-items: top;
  &:hover {
    background: #0AC18E;
    border: 2px solid #f8f8f8;
  }
`;
const StyledPledgeLogo = styled.div`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: flex;
  border: 0px solid red;
`;
const SubmitButton = styled.button`
  position: relative;
  background-color: #0AC18E;
  color: #F8F8F8;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  width: 150px;
  min-width: 50px;
  height: 32px;
  top: 15px;
  margin-bottom: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: #0cd9a0;
    border: 2px solid #fff;
  }
  @media (max-width: 1000px) {
    width: 125px;
  }
  &:disabled {
    background: #aaa;
    cursor: not-allowed;
    border: 2px solid #aaa;
  }
`
const StyledTitle = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: 35px;
  top: 0px;
  background: #0AC18E;
  border-radius: 8px 8px 0px 0px;
  color: #F8F8F8;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
  z-index: 0;
`;
const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  justify-content: center;
  align-items: center;
`;
export default ConsolidateModal;