//V1
//export const AddressCashStarter = 'bitcoincash:pvqmmly0qazjf0ylan526m3hht8l08kxqdwwn42jfgxum3ppu5naz7t0mr8k6';
//export const AddressCashStarterManager = 'bitcoincash:pdp56dwz9lc72d9g4j5x93hmwak5ma7uqqupqus7xjjpv7y0qvnagg8pv2zf4';
//export const MasterCategoryID = '4f65066b3b7555f3c31d349c37acb45db946fc13e5a83873e70754a4f9375744'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '445737f9a45407e77338a8e513fc46b95db4ac379c341dc3f355753b6b06654f'; //LittleEndian - vm script use

//V3?
//export const AddressCashStarter = 'bitcoincash:pv97da3h4zq9wn62q0uardkeyzq7te7wz45q6te27tz257x42m8xc8xs9dv90';
//export const AddressCashStarterManager = 'bitcoincash:p0wapwf5hg6la7pkdzyja7h5rwx0d6pqqm2s6cle3mn2sepnzf84qp993ktle';
//export const AddressTokensCashStarter = 'bitcoincash:rv97da3h4zq9wn62q0uardkeyzq7te7wz45q6te27tz257x42m8xc44vy5duy';
//export const AddressTokensCashStarterManager = 'bitcoincash:r0wapwf5hg6la7pkdzyja7h5rwx0d6pqqm2s6cle3mn2sepnzf84qnkes02xj';
//export const MasterCategoryID = 'ace7a36f9d7dc9d5760032ebf3e2b8f16d953ed4ac374265e92a69c81dc9a332'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '32a3c91dc8692ae9654237acd43e956df1b8e2f3eb320076d5c97d9d6fa3e7ac'; //LittleEndian - vm script use

//V4 with FailMinter (stoppedWorking on it)
//export const AddressCashStarter = 'bitcoincash:pwc2ywp03nuvyje2850f6tuptcngahxrnm9cxld0kqxazq5sh3nl70c48g7d3';
//export const AddressCashStarterManager = 'bitcoincash:p09uqvnu785mdanh32xktk7vul8ty35unhg48nvgenxe5dsre59fkhdlmg889';
//export const AddressFailMinter = 'bitcoincash:pwqr2cqyjfyr5t06a2malqldj48rhhtj3xcjx0n968vjg04d3qylvfh5904qd';
//export const AddressTokensCashStarter = 'bitcoincash:rwc2ywp03nuvyje2850f6tuptcngahxrnm9cxld0kqxazq5sh3nl7atfx3l56';
//export const AddressTokensCashStarterManager = 'bitcoincash:r09uqvnu785mdanh32xktk7vul8ty35unhg48nvgenxe5dsre59fk97r63x7w';
//export const AddressTokensFailMinter = 'bitcoincash:rwqr2cqyjfyr5t06a2malqldj48rhhtj3xcjx0n968vjg04d3qylvmygyk5ex';
//export const MasterCategoryID = '9fca597352b710baac9f416983a648718dabf71bedc0d6cea86875ca007e70f8'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'f8707e00ca7568a8ced6c0ed1bf7ab8d7148a68369419facba10b7527359ca9f'; //LittleEndian - vm script use
//export const MasterCategoryIDFailMinter = '80fb72714b9e1505b51461143d53556df74e4f93809452b031ae10fa649143e8'; //BigEndian - frontend use
//export const MasterCategoryIDFailMinterLE = 'e8439164fa10ae31b0529480934f4ef76d55533d146114b505159e4b7172fb80'; //LittleEndian - vm script use

//FundMeV3_ServiceFee_Modules
//export const AddressCashStarter = 'bitcoincash:pvtgum3chf92kg4eammt59w7vh5juyu2xy3hlvd2kze98qy0tv0xsjsypw20u';  //address hash: 168e6e38ba4aab22b9eef6ba15de65e92e138a31237fb1aab0b253808f5b1e68
//export const AddressTokensCashStarter = 'bitcoincash:rvtgum3chf92kg4eammt59w7vh5juyu2xy3hlvd2kze98qy0tv0xsqrcqhtkh';

//export const AddressCashStarterManager = 'bitcoincash:pwmal5k45s2k6rlfu6eqgm65qhh72nyje0e0geds85mw8uy3axtl25usz6x5l';
//export const AddressTokensCashStarterManager = 'bitcoincash:rwmal5k45s2k6rlfu6eqgm65qhh72nyje0e0geds85mw8uy3axtl2x0vrr8d5';

//export const AddressCashStarterRefund = 'bitcoincash:p09znnudefu9uktx68848h9cujlwh57x09hlx5lahsn36qd5vnzavng0ejsz6';
//export const AddressTokensCashStarterRefund = 'bitcoincash:r09znnudefu9uktx68848h9cujlwh57x09hlx5lahsn36qd5vnzavpmnct3m3';

//export const AddressCashStarterFail = 'bitcoincash:pwpstp3dxqn2nau0652mp680g2rg3q8fwt9e3hmzvgjvtw4lsu0zx0qk6jl7y';
//export const AddressTokensCashStarterFail = 'bitcoincash:rwpstp3dxqn2nau0652mp680g2rg3q8fwt9e3hmzvgjvtw4lsu0zxan2mt780';

//export const AddressCashStarterCancel = 'bitcoincash:pdyc5zm94pmkjxvkp4hdfsp5frfmlrctzatalpcvd2nnnuf7lrjvugannx2x6';
//export const AddressTokensCashStarterCancel = 'bitcoincash:rdyc5zm94pmkjxvkp4hdfsp5frfmlrctzatalpcvd2nnnuf7lrjvu6w0jltl3';

//export const AddressCashStarterClaim = 'bitcoincash:pvsx9eruwratvktmgvrfgautt56kdxzlletkr5td4cwxvrkpwws4jq257n2mc';
//export const AddressTokensCashStarterClaim = 'bitcoincash:rvsx9eruwratvktmgvrfgautt56kdxzlletkr5td4cwxvrkpwws4jjegl2tzn';

//export const MasterCategoryID = '60fad1add2d3504c635caf56f24b85b8cb3622ad4dc77a8fc6a280a22a76069d'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '9d06762aa280a2c68f7ac74dad2236cbb8854bf256af5c634c50d3d2add1fa60'; //LittleEndian - vm script use

//export const MasterCategoryIDFailMinter = ''; //BigEndian - frontend use
//export const MasterCategoryIDFailMinterLE = ''; //LittleEndian - vm script use

//V4 with FailMinter
//export const AddressCashStarter = 'bitcoincash:pdasv327pfs7zddhw7lhzerxhvwm5dvm0xkzejftz5tljuawvnsp7cvqphz3p';  //address hash: 7b06455e0a61e135b777bf716466bb1dba359b79ac2cc92b1517f973ae64e01f
//export const AddressTokensCashStarter = 'bitcoincash:rdasv327pfs7zddhw7lhzerxhvwm5dvm0xkzejftz5tljuawvnsp72luqwrg2';
//export const AddressCashStarterManager = 'bitcoincash:pw8y050y5z2czyl5c6j5c7p57e928c4ykc4tlak5w7cf3049ylsqyxx89hamc';
//export const AddressTokensCashStarterManager = 'bitcoincash:rw8y050y5z2czyl5c6j5c7p57e928c4ykc4tlak5w7cf3049ylsqy54mywuzn';
//export const AddressCashStarterRefund = 'bitcoincash:pwl2fvjm850euwagfm4f94vurgdwfxh5zflcv67ykx4w37hsqqmyxcfdj55xq';
//export const AddressTokensCashStarterRefund = 'bitcoincash:rwl2fvjm850euwagfm4f94vurgdwfxh5zflcv67ykx4w37hsqqmyx263nd4lt';
//export const AddressCashStarterStop = 'bitcoincash:pd9p923rnjk23zncn64yzvllvg23pelk3cycdgth2sm9cfw2d57v65t7636c6';
//export const AddressTokensCashStarterStop = 'bitcoincash:rd9p923rnjk23zncn64yzvllvg23pelk3cycdgth2sm9cfw2d57v6xczmgmp3';
//export const AddressCashStarterCancel = 'bitcoincash:p04ncjvjrns4u3yeshewzg78yerln0gqj68ch3yufzdrfv5pspnvcrl7vlzzh';
//export const AddressTokensCashStarterCancel = 'bitcoincash:r04ncjvjrns4u3yeshewzg78yerln0gqj68ch3yufzdrfv5pspnvc3vzdxrmu';
//export const AddressCashStarterClaim = 'bitcoincash:pw20eezgavdtyf6wr44nl0dq77308c9nqhl9vvdep72pfnna29dg2t08e5ylp';
//export const AddressTokensCashStarterClaim = 'bitcoincash:rw20eezgavdtyf6wr44nl0dq77308c9nqhl9vvdep72pfnna29dg2eumcd9x2';
//export const MasterCategoryID = '8139379bb671e01f9cdd5c5d7833343a408cf84828d30a1b0cdff6b653e57ef4'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'f47ee553b6f6df0c1b0ad32848f88c403a3433785d5cdd9c1fe071b69b373981'; //LittleEndian - vm script use
//export const MasterCategoryIDFailMinter = ''; //BigEndian - frontend use
//export const MasterCategoryIDFailMinterLE = ''; //LittleEndian - vm script use

//V5 with PledgeIDs (new CashStarter + CashStarterManager) //forgot to update pledgeID into campaignUTXO commitment
//export const AddressCashStarter = 'bitcoincash:p0rqjjcsn98vm8ac575je98fqhgzm4aa5cs2x4mg9qpr3qqztye2gu88kgu2m';  //address hash: c6094b10994ecd9fb8a7a92c94e905d02dd7bda620a3576828023880025932a4
//export const AddressTokensCashStarter = 'bitcoincash:r0rqjjcsn98vm8ac575je98fqhgzm4aa5cs2x4mg9qpr3qqztye2gw5mh3ans';

//export const AddressCashStarterManager = 'bitcoincash:pwsdsuqh035r5fde9z7mph0p0l6t73z7yg06893tymhekt4fdff82uxn6cqzw';
//export const AddressTokensCashStarterManager = 'bitcoincash:rwsdsuqh035r5fde9z7mph0p0l6t73z7yg06893tymhekt4fdff82w40mppm9';

//split(5) for pledgeNFT
//export const AddressCashStarter = 'bitcoincash:pwnfwmlw0yhavkuz7yjld396ssznucsvys8l55nj057k8pnyzmerj3ec2v4eg';  //address hash: a6976fee792fd65b82f125f6c4ba84053e620c240ffa52727d3d63866416f239
//export const AddressTokensCashStarter = 'bitcoincash:rwnfwmlw0yhavkuz7yjld396ssznucsvys8l55nj057k8pnyzmerjr2yt45qr';
//export const AddressCashStarterManager = 'bitcoincash:pdgxk3ghz3dnkrdav664gz5mkmlmkrhulvfyftfguua09wefxtxuxlna3fapa';
//export const AddressTokensCashStarterManager = 'bitcoincash:rdgxk3ghz3dnkrdav664gz5mkmlmkrhulvfyftfguua09wefxtxuxdqpssuck';

//V5 with PledgeIDs (new CashStarter + CashStarterManager)
//export const AddressCashStarter = 'bitcoincash:p0gvajt8lnkyftu2jk4zg8yz8swd99a2cdhmkj54kadcujspdtdmkwkug26dp';  //address hash: d0cec967fcec44af8a95aa241c823c1cd297aac36fbb4a95b75b8e4a016adbbb
//export const AddressTokensCashStarter = 'bitcoincash:r0gvajt8lnkyftu2jk4zg8yz8swd99a2cdhmkj54kadcujspdtdmku9qfnm52';

//export const AddressCashStarterManager = 'bitcoincash:pvywa24z9kwulcw3lgw287y6x8xgfay5a7mmvrk49d8pk29q4ycf6cjns4z0m';
//export const AddressTokensCashStarterManager = 'bitcoincash:rvywa24z9kwulcw3lgw287y6x8xgfay5a7mmvrk49d8pk29q4ycf62p03vrks';

//export const AddressCashStarterRefund = 'bitcoincash:pwl2fvjm850euwagfm4f94vurgdwfxh5zflcv67ykx4w37hsqqmyxcfdj55xq';
//export const AddressTokensCashStarterRefund = 'bitcoincash:rwl2fvjm850euwagfm4f94vurgdwfxh5zflcv67ykx4w37hsqqmyx263nd4lt';

//export const AddressCashStarterStop = 'bitcoincash:pd9p923rnjk23zncn64yzvllvg23pelk3cycdgth2sm9cfw2d57v65t7636c6';
//export const AddressTokensCashStarterStop = 'bitcoincash:rd9p923rnjk23zncn64yzvllvg23pelk3cycdgth2sm9cfw2d57v6xczmgmp3';

//export const AddressCashStarterCancel = 'bitcoincash:p04ncjvjrns4u3yeshewzg78yerln0gqj68ch3yufzdrfv5pspnvcrl7vlzzh';
//export const AddressTokensCashStarterCancel = 'bitcoincash:r04ncjvjrns4u3yeshewzg78yerln0gqj68ch3yufzdrfv5pspnvc3vzdxrmu';

//export const AddressCashStarterClaim = 'bitcoincash:pw20eezgavdtyf6wr44nl0dq77308c9nqhl9vvdep72pfnna29dg2t08e5ylp';
//export const AddressTokensCashStarterClaim = 'bitcoincash:rw20eezgavdtyf6wr44nl0dq77308c9nqhl9vvdep72pfnna29dg2eumcd9x2';

//export const MasterCategoryID = '8139379bb671e01f9cdd5c5d7833343a408cf84828d30a1b0cdff6b653e57ef4'; //BigEndian - frontend use
//export const MasterCategoryIDLE = 'f47ee553b6f6df0c1b0ad32848f88c403a3433785d5cdd9c1fe071b69b373981'; //LittleEndian - vm script use

//export const MasterCategoryIDFailMinter = ''; //BigEndian - frontend use
//export const MasterCategoryIDFailMinterLE = ''; //LittleEndian - vm script use

//V6 on chipnet
//export const AddressCashStarter = 'bchtest:p0qk88n7pqvdgrdcz8k9hzlcc3re9nh3x78m06eeddrajg532dz2zue57gujf';  //address hash: c1639e7e0818d40db811ec5b8bf8c44792cef1378fb7eb396b47d922915344a1
//export const AddressTokensCashStarter = 'bchtest:r0qk88n7pqvdgrdcz8k9hzlcc3re9nh3x78m06eeddrajg532dz2zw2gl3atz';

//export const AddressCashStarterManager = 'bchtest:pvm0ke5e4m9pzz2xr7w4yxpwathhtet9arpzj5gathjjev6c4qa96uevj6m8q';
//export const AddressTokensCashStarterManager = 'bchtest:rvm0ke5e4m9pzz2xr7w4yxpwathhtet9arpzj5gathjjev6c4qa96w2snr67t';

//export const AddressCashStarterRefund = 'bchtest:p08cx0nrhg60kcj593vrct4w333mtwdz2a8ruutwqvqtsn08snhtxey8xa6uf';
//export const AddressTokensCashStarterRefund = 'bchtest:r08cx0nrhg60kcj593vrct4w333mtwdz2a8ruutwqvqtsn08snhtxthm8ym9z';

//export const AddressCashStarterStop = 'bchtest:pwnvpgs4qkn5stp8mws84w8u9tflpg9dn6s8zgzlu7fhtmd2ylmy7x7evlyh9';
//export const AddressTokensCashStarterStop = 'bchtest:rwnvpgs4qkn5stp8mws84w8u9tflpg9dn6s8zgzlu7fhtmd2ylmy75d9dx9ww';

//export const AddressCashStarterCancel = 'bchtest:pwqvx9z4g3rgvnrst3nu90sm29qfjg9geappvddx2cxe38ddxuv4vpf8dkrdw';
//export const AddressTokensCashStarterCancel = 'bchtest:rwqvx9z4g3rgvnrst3nu90sm29qfjg9geappvddx2cxe38ddxuv4vn6mv0z59';

//export const AddressCashStarterClaim = 'bchtest:pvf7rqpy78kpdzmc4hfux8nyucvfrm2j87ycs8md0xwq7egcql96u47909z6c';
//export const AddressTokensCashStarterClaim = 'bchtest:rvf7rqpy78kpdzmc4hfux8nyucvfrm2j87ycs8md0xwq7egcql96u8dewurrn';

//export const MasterCategoryID = '9ad58b45c5ea4e9a26b4dbde118c7fb8c610e549ae12a61ae69bd4270f816751'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '5167810f27d49be61aa612ae49e510c6b87f8c11dedbb4269a4eeac5458bd59a'; //LittleEndian - vm script use

//V7 on chipnet
//export const AddressCashStarter = 'bchtest:p03mnwv76v4ddj6uawmxxk54jacxsramwzhf3mjjvw9p5ph5h0kqqlymws3ft';  //address hash: e3b9b99ed32ad6cb5cebb6635a959770680fbb70ae98ee52638a1a06f4bbec00
//export const AddressTokensCashStarter = 'bchtest:r03mnwv76v4ddj6uawmxxk54jacxsramwzhf3mjjvw9p5ph5h0kqqdh80fssq';

//export const AddressCashStarterManager = 'bchtest:pw8d99q8jpjj4kq04zkh2zzsm8d9fnp9pj3lfytc2a96r5z44lrxscnnrc7u3';
//export const AddressTokensCashStarterManager = 'bchtest:rw8d99q8jpjj4kq04zkh2zzsm8d9fnp9pj3lfytc2a96r5z44lrxs2q0zpl96';

//export const AddressCashStarterRefund = 'bchtest:p08cx0nrhg60kcj593vrct4w333mtwdz2a8ruutwqvqtsn08snhtxey8xa6uf';
//export const AddressTokensCashStarterRefund = 'bchtest:r08cx0nrhg60kcj593vrct4w333mtwdz2a8ruutwqvqtsn08snhtxthm8ym9z';

//export const AddressCashStarterStop = 'bchtest:pwnvpgs4qkn5stp8mws84w8u9tflpg9dn6s8zgzlu7fhtmd2ylmy7x7evlyh9';
//export const AddressTokensCashStarterStop = 'bchtest:rwnvpgs4qkn5stp8mws84w8u9tflpg9dn6s8zgzlu7fhtmd2ylmy75d9dx9ww';

//export const AddressCashStarterCancel = 'bchtest:pwqvx9z4g3rgvnrst3nu90sm29qfjg9geappvddx2cxe38ddxuv4vpf8dkrdw';
//export const AddressTokensCashStarterCancel = 'bchtest:rwqvx9z4g3rgvnrst3nu90sm29qfjg9geappvddx2cxe38ddxuv4vn6mv0z59';

//export const AddressCashStarterClaim = 'bchtest:pvf7rqpy78kpdzmc4hfux8nyucvfrm2j87ycs8md0xwq7egcql96u47909z6c';
//export const AddressTokensCashStarterClaim = 'bchtest:rvf7rqpy78kpdzmc4hfux8nyucvfrm2j87ycs8md0xwq7egcql96u8dewurrn';

//export const MasterCategoryID = '9ad58b45c5ea4e9a26b4dbde118c7fb8c610e549ae12a61ae69bd4270f816751'; //BigEndian - frontend use
//export const MasterCategoryIDLE = '5167810f27d49be61aa612ae49e510c6b87f8c11dedbb4269a4eeac5458bd59a'; //LittleEndian - vm script use

//V8 on mainnet
export const AddressCashStarter = 'bitcoincash:p03u4v845j4rhzyc63cgm0arksfx5u3atkvz43xzdy0r8pql4qm37j50tje8p';  //address hash: e3cab0f5a4aa3b8898d4708dbfa3b4126a723d5d982ac4c2691e33841fa8371f
export const AddressTokensCashStarter = 'bitcoincash:r03u4v845j4rhzyc63cgm0arksfx5u3atkvz43xzdy0r8pql4qm37q8n2tc72';

export const AddressCashStarterManager = 'bitcoincash:p0mhegq4agfajfjda5al2nwcz6v5ztvct7p3tdp4kfzpqmpv8v9xu5j8aunkq';
export const AddressTokensCashStarterManager = 'bitcoincash:r0mhegq4agfajfjda5al2nwcz6v5ztvct7p3tdp4kfzpqmpv8v9xuxpmu9j0t';

export const AddressCashStarterRefund = 'bitcoincash:pvpf5ppmx2dw60u5qm2jcarm0df6f7ldzqk8kr25q4gzmm4802v7wmfcv4eyz';
export const AddressTokensCashStarterRefund = 'bitcoincash:rvpf5ppmx2dw60u5qm2jcarm0df6f7ldzqk8kr25q4gzmm4802v7wf6ydvcaf';

export const AddressCashStarterStop = 'bitcoincash:p0z4d9acwvhzyr5qqtqt7ejj3ur4na3j6u2lpeg0rujz5ysjay86qgea803ql';
export const AddressTokensCashStarterStop = 'bitcoincash:r0z4d9acwvhzyr5qqtqt7ejj3ur4na3j6u2lpeg0rujz5ysjay86q62pxkse5';

export const AddressCashStarterCancel = 'bitcoincash:pdwugz8d34vclx45w5negqp6rx3535z0k9uynlp7ey4p6g6lyantgfqcj7n2n';
export const AddressTokensCashStarterCancel = 'bitcoincash:rdwugz8d34vclx45w5negqp6rx3535z0k9uynlp7ey4p6g6lyantgmnyn8jnc';

export const AddressCashStarterClaim = 'bitcoincash:pw2gwuvr5s5uz4g8qylu44ut6dkp26s56w9wvpa3dl95r9nj47s7q6evhtleg';
export const AddressTokensCashStarterClaim = 'bitcoincash:rw2gwuvr5s5uz4g8qylu44ut6dkp26s56w9wvpa3dl95r9nj47s7qg2skj7qr';

export const MasterCategoryID = '483745b8b16870e60acbdb400c7973672835002acbcdc39b09d9074e10eac964'; //BigEndian - frontend use
export const MasterCategoryIDLE = '64c9ea104e07d9099bc3cdcb2a0035286773790c40dbcb0ae67068b1b8453748'; //LittleEndian - vm script use